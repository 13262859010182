import React from 'react'
import { isNullOrUndefined } from 'util'
import { createConsumer } from '@rails/actioncable'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Api from 'commons/api'
import Config from 'commons/config'
import * as Storage from 'commons/storage'
import * as utility from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'

const styles = theme => ({
  close: {
    padding: 0,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  snackbar: {
    top: theme.spacing(5),
  },
  snackbarContent: {
    backgroundColor: theme.palette.secondary.main,
  },
})

const ALL_PATHS = [
  // entry
  '/Entry',
  '/Photo',
  '/PhotoConfirm',
  '/PhoneNumber',
  '/PhoneNumberConfirm',
  '/Identify',
  '/IdentifyConfirm',
  // app
  '/Home',
  '/Friends',
  '/Chat',
  '/FriendRequest',
  '/LoverEntry',
  '/ApprovalLoverEntry',
  '/Notifications',
  '/AccountInfo',
  '/MyMail',
  '/AuthCode',
  '/MyPassword',
  '/MyNotifications',
  '/ProfileEdit',
  '/IntroductionProfile',
  '/Profile',
  '/MyProfile',
  '/InterestedThings',
  '/FAQ',
  '/MatchingFilter',
  '/MessageEdit',
  '/Feedback',
  '/ViolationReport',
  '/Campaigns',
  '/InvitationCampaign',
  '/SpecialOffer',
  '/Share',
  '/SecretRooms',
  '/ShopSearch',
  '/Shops',
  '/ShopDetail',
  '/Terms',
  '/PrivacyStatement',
  '/Theater',
  '/TheaterExit',
  '/TheaterRoom',
  '/Facebook',
  // partner
  '/PartnerHome',
  '/PartnerPasswordChange',
  '/PartnerLink',
  '/PartnerResults',
  '/PartnerUsers',
]

const MATCHING_PATHS = [
  '/Friends',
  '/Chat',
  '/FriendRequest',
]

const UNNECESSARY_TOKEN_PATHS = [
  '/',
  '/Login',
  '/LoginAuthSmsCode',
  '/Signup',
  '/SignupCheck',
  '/Passcode',
  '/Password',
  '/SearchCompany',
  '/SearchCompanyResult',
  '/SearchCompanyAccepted',
].map(p => p.toUpperCase())

export class AppRoot extends BaseComponent {
  constructor (props) {
    super(props)
    this.handleClose = this.handleClose.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleNewMessage = this.handleNewMessage.bind(this)
    this.isNotifyableMessage = this.isNotifyableMessage.bind(this)
    this.state = {
      open: false,
      message: null,
      friend: null,
      redraw: false,
      newMessage: null,
    }

    // Action Cable
    this.actionCable = null
    this.notificationChannel = null
    this.openingChannels = false
    this.closingChannels = false

    this._isMounted = false
  }

  handleClose () {
    this.setState({ open: false })
  }

  async handleConfirm () {
    this.setState({ open: false })
    try {
      const loadedFriend = await this.loadFriendDetailWithId(this.state.friend.id)
      if (/^friend_pending(_.+)?$/.test(loadedFriend.type)) {
        this.props.setScreen('Friends')
      } else {
        this.props.setNewMessage(this.state.newMessage)
        if (this.props.location.pathname.toUpperCase() === '/Chat'.toUpperCase()) {
          this.props.history.replace('/Chat')
        } else {
          this.props.setScreen('Chat')
        }
      }
    } catch (error) {
      this.handleApiError(error)
    }
  }

  componentWillMount () {
    const path = this.props.location.pathname
    if (UNNECESSARY_TOKEN_PATHS.includes(path.toUpperCase())) {
      this.setState({ redraw: true })
    } else {
      if (Storage.getToken()) {
        this.checkLogin()
      } else {
        this.props.setIsLogin(false)
        this.setState({ redraw: true })
      }
    }
  }

  async componentWillReceiveProps (nextProps) {
    switch (Config.deployMode) {
      case 'app':
        if (nextProps.isLogin) {
          if (!this.openingChannels) {
            this.openingChannels = true
            this.closingChannels = false
            this.openChannels()
          }
        } else {
          if (!this.closingChannels) {
            this.openingChannels = false
            this.closingChannels = true
            this.closeChannels()
          }
        }
        break
      case 'entry': {
        const path = this.props.location.pathname
        if (UNNECESSARY_TOKEN_PATHS.includes(path.toUpperCase())) { return }
        if (path !== nextProps.location.pathname) {
          const user = await BaseComponent.loadUser(this.props)
          const idStatus = user.identification_status
          if (idStatus === 'checking' || idStatus === 'checked') {
            this.props.setScreen('IdChecking')
          }
        }
        break
      }
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
    if (!this.closingChannels) {
      this.closeChannels()
    }
  }

  openChannels () {
    if (isNullOrUndefined(this.actionCable)) {
      let url = `${Config.wssBaseUrl}${Storage.getToken()}`
      this.actionCable = createConsumer(url)
    }

    if (isNullOrUndefined(this.notificationChannel)) {
      let params = { channel: 'NotificationChannel' }
      let handler = this.channelHandler(this.handleNotification)
      this.notificationChannel = this.actionCable.subscriptions.create(params, handler)
    }
  }

  closeChannels () {
    if (!isNullOrUndefined(this.notificationChannel)) {
      this.notificationChannel.unsubscribe()
      this.notificationChannel = null
    }

    if (!isNullOrUndefined(this.actionCable)) {
      this.actionCable.disconnect()
      this.actionCable = null
    }
  }

  channelHandler = receivedHandler => {
    return {
      connected: () => {
        console.log('AppRoot', 'ActionCable', 'connected')
      },
      disconnected: () => {
        console.log('AppRoot', 'ActionCable', 'disconnected')
      },
      rejected: () => {
        console.log('AppRoot', 'ActionCable', 'rejected')
      },
      received: data => receivedHandler(data)
    }
  }

  handleNotification = data => {
    switch (data.event) {
      case 'new_message':
        this.handleNewMessage(data.user, data.message)
        break
      default:
        console.log(`Unknown notification: ${data.event}`)
    }
  }

  handleNewMessage = (user, msg) => {
    if (!this.isNotifyableMessage(msg)) return

    let currentFriend = this.props.friend
    if (currentFriend == null) {
      currentFriend = Storage.getFriend()
    }

    let chatting = (
      this.props.location.pathname.toUpperCase() === '/Chat'.toUpperCase()
      &&
      currentFriend.chat_room_id === msg.chat_room_id
    )
    if (chatting) {
      console.log(`${user.nick_name}さんとトーク中`)
    } else {
      this.uploadNewMessageCount(user)
      this.setState({
        message: this.getNotifyMessage(user, msg),
        friend: user,
        open: true,
        redraw: true,
        newMessage: msg,
      })
    }
  }

  // 新規メッセージを通知するメッセージ
  getNotifyMessage (friendUser, msg) {
    switch (msg.navi_type) {
      case 'angel':
        return `狛犬からメッセージ (${friendUser.nick_name}さん)`
      case 'devil':
        return `狛犬からメッセージ (${friendUser.nick_name}さん)`
      default:
        return `${friendUser.nick_name}さんからメッセージ`
    }
  }

  async uploadNewMessageCount (friendUser) {
    try {
      await this.loadFriendAndUpdateFriends(friendUser.id)
    } catch (error) {
      this.handleApiError(error)
    }
  }

  // 以下のメッセージを除外
  // ・好感度アンケート回答
  // ・自分が送信したメッセージ
  // ・管理者・AIからの相手宛メッセージ
  isNotifyableMessage (msg) {
    if (msg.op_type === 'answer_secret_question') return false
    if (msg.op_type === 'answer_question') return false
    if (msg.op_type === 'delete_message') return false
    if (msg.op_type === 'read_messages') return false

    if (msg.user_id === this.props.userId) return false
    if (isNullOrUndefined(msg.navi_to)) {
      if (!msg.communication_disabled) return true
    } else {
      if (msg.navi_to === this.props.userId) return true
    }
    return false
  }

  setAppLoading (loading) {
    if (Config.deployMode === 'app') {
      this.props.setAppLoading(loading)
    } else {
      this.props.setLoading(loading)
    }
  }

  async checkLogin () {
    this.setAppLoading(true)
    try {
      const auth = await this.checkSession()
      if (this.props.location.pathname.toUpperCase() === '/Entry'.toUpperCase()) {
        const authId = utility.getUrlParam('auth_id')
        if (authId && authId !== auth.id) {
          this.clearSession()
          this.props.setIsLogin(false)
          this.setStateIfMounted({ redraw: true })
          return
        }
      }
      // identification_status確認後、必要データ取得
      await this.checkIdStatus()
      switch (Config.deployMode) {
        case 'partner':
          if (!this.props.admin) { await this.loadPartnerUser() }
          await this.loadMasters()
          this.props.setScreen('PartnerHome')
          this.setStateIfMounted({ redraw: true })
          return
        case 'entry': {
          const myOrg = await Api.getMyOrganization()
          this.props.setMyOrganization(myOrg)
          this.loadIdTexts(myOrg)
          Storage.clearShowPendingDialog()
          await this.loadMasters()
          this.setStateIfMounted({ redraw: true })
          return
        }
        case 'members': {
          const myOrg = await Api.getMyOrganization()
          this.props.setMyOrganization(myOrg)
          await BaseComponent.loadMatchingState(this.props)
          await BaseComponent.loadSubscription(this.props)
          await BaseComponent.loadFriends(this.props)
          this.props.setScreen('Home')
          this.setStateIfMounted({ redraw: true })
          return
        }
        case 'app': {
          const myOrg = await Api.getMyOrganization()
          this.props.setMyOrganization(myOrg)
          await BaseComponent.loadSubscription(this.props)
          await BaseComponent.loadSpecialOffers(this.props)
          await this.loadFriends()
          await this.loadChatRoomsBy()
          await this.loadMasters()
          await BaseComponent.loadTutorialReadStates(this.props)
          if (this.isInvalidLocationPath()) {
            this.props.setScreen('Home')
          } else if (this.isMatchingLocationPath()) {
            await this.loadMatchings()
          }
          this.setStateIfMounted({ redraw: true })
          return
        }
      }
    } catch (error) {
      if (!this.handleApiError(error)) {
        await Api.addErrorLog(error)
        Storage.clearToken()
        Storage.clearFriend()
        this.props.setIsLogin(false)
        this.props.setScreen('/')
        this.setStateIfMounted({ redraw: true })
      }
    } finally {
      this.setAppLoading(false)
    }
  }

  async checkSession () {
    try {
      const auth = await this.validateSession()
      return auth
    } catch (error) {
      await Api.addErrorLog(error)
      Storage.clearToken()
      Storage.clearFriend()
      this.props.setIsLogin(false)
      this.props.setScreen('/')
      this.setStateIfMounted({ redraw: true })
    }
  }

  async checkIdStatus () {
    const user = await BaseComponent.loadUser(this.props)
    const idStatus = user.identification_status
    switch (Config.deployMode) {
      case 'app':
      case 'members': {
        if (idStatus === 'not_submitted' || idStatus === 'pending' || idStatus === 'checking') {
          const message = idStatus === 'checking' ? '只今入会審査中のためしばらくお待ちください。' : '入会審査完了をお願いします。'
          BaseComponent.showImportantErrorMessage(this.props, message, () => this.logout())
        }
        break
      }
      case 'entry':
        switch (idStatus) {
          // 審査中
          case 'checking':
            this.props.setScreen('IdChecking')
            break
          // 審査 -> 保留中
          case 'pending':
            this.toEntryNextStepPending(user)
            break
          // 未提出 (メールアドレス登録〜本人確認資料提出前)
          case 'not_submitted':
            if (!BaseComponent.isCompletedEntrySheet(user)) {
              this.props.setScreen('Entry')
            } else if (!this.props.isPhoneVerified) {
              // 電話番号登録済みの場合 BaseComponent#validateSession() で true が入った状態になっている。
              this.props.setScreen('PhoneNumber')
            } else if (!BaseComponent.isCompletedLoveSheet(user)) {
              this.props.setScreen('Love')
            } else if (utility.isEmpty(user.photo)) {
              this.props.setScreen('Photo')
            } else if (!user.first_identification_updated_at) {
              this.props.setScreen('Identify')
            } else {
              const myOrg = await Api.getMyOrganization()
              if (myOrg?.affiliation_text_id) {
                this.props.setScreen('Affiliation')
              }else if (myOrg?.full_paid_enabled) {
                this.props.setScreen('Identify')
              } else {
                this.props.setScreen('PaymentRegistration')
              }
            }
            break
          default:
            this.props.setScreen('IdChecking')
            break
        }
        break
    }
  }

  // URLパスが想定しないものであるか
  isInvalidLocationPath () {
    let invalid = true
    ALL_PATHS.some(value => {
      if (this.props.location.pathname.toUpperCase() === value.toUpperCase()) {
        invalid = false
        return value
      }
      return null
    })
    return invalid
  }

  // URLパスがLoadMaching必要であるか
  isMatchingLocationPath () {
    let matching = false
    MATCHING_PATHS.some(value => {
      if (this.props.location.pathname.toUpperCase() === value.toUpperCase()) {
        matching = true
        return value
      }
      return null
    })
    return matching
  }

  createSnackBar () {
    const { classes } = this.props
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        open={this.state.open}
        onClose={this.handleClose}
        classes={{ anchorOriginTopCenter: classes.snackbar }}
      >
        <SnackbarContent
          className={classes.snackbarContent}
          message={this.state.message}
          action={[
            <Button key="undo" color="primary" size="small" onClick={this.handleConfirm}>
              確認
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose} >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }

  render () {
    // ユーザの状態をチェック
    if (this.state.redraw) {
      if (this.props.isLogin) {
        return (
          <div>
            {this.props.children}
            {this.createSnackBar()}
          </div>
        )
      }
      return (this.props.children)
    } else {
      return <div />
    }
  }
}

export default withStyles(styles)(AppRoot)
