import React, { useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Api from 'commons/api'
import Storage from 'commons/storage'
import { isEmpty, isCarrierAddress, decodeSanitaize } from 'commons/utility'
import EntryCampaignBox from 'components/entry/EntryCampaignBox'
import EntryFooter from 'components/entry/EntryFooter'
import EntryHeader from 'components/entry/EntryHeader'
import CarrierMailAddressAttentionDialog from 'components/notifications/CarrierMailAddressAttentionDialog'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'

const marriageIntentionValues = [
  { id: 'yes', display_name: '結婚意思があります' },
  { id: 'if_possible', display_name: '良い人がいれば結婚意思があります' },
  { id: 'no', display_name: 'パートナーシップの関係を望みます' },
]

const sexValues = [
  { id: 'male', display_name: '男性' },
  { id: 'female', display_name: '女性' },
  { id: 'others', display_name: 'その他' },
]

const countryValues = [
  { id: 'japan', display_name: '日本' },
  { id: 'others', display_name: '海外' },
]

const inflowSourceValues = [
  { id: 'pamphlet', display_name: 'パンフレット' },
  { id: 'poster', display_name: 'ポスター' },
  { id: 'bulletin_board', display_name: '掲示板' },
  { id: 'mail', display_name: 'メール' },
  { id: 'company_newsletter', display_name: '社内報' },
  { id: 'novelty', display_name: 'ノベルティ' },
  { id: 'seminar', display_name: 'セミナー' },
  { id: 'service_card', display_name: 'サービスカード' },
  { id: 'sns', display_name: 'AillのSNS' },
  { id: 'from_friend', display_name: '知人から聞いた' },
  { id: 'web', display_name: 'Web検索' },
  { id: 'youtube', display_name: 'YouTube' },
  { id: 'store', display_name: 'App Store/Google Play検索' },
  { id: 'others', display_name: 'その他' },
]

const useStyles = makeStyles(theme => ({
  body: {
    backgroundColor: theme.palette.primary.main,
    minHeight: `calc(${window.innerHeight}px - ${theme.styles.entryFooter.height}px)`,
    paddingTop: sp => theme.spacing(sp ? 4.5 : 8.25),
  },
  title: {
    fontSize: sp => sp ? 19 : 32,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: 1,
    letterSpacing: sp => sp ? '1.9px' : '3.2px',
    textAlign: 'center',
  },
  titleBar: {
    height: sp => sp ? 2 : 4,
    width: sp => sp ? 145 : 234,
    background: '#616AFA',
    margin: 'auto',
    marginTop: sp => theme.spacing(sp ? 1.5 : 2),
  },
  container: {
    paddingTop: sp => theme.spacing(sp ? 3.25 : 5.25),
    paddingBottom: theme.spacing(5),
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  topText: {
    fontSize: sp => sp ? 14 : 28,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: 1,
    letterSpacing: sp => sp ? '1.4px' : '2.8px',
    textAlign: 'center',
    marginBottom: sp => theme.spacing(sp ? 2.5 : 3.75),
  },
  subtitle: {
    fontSize: sp => sp ? 14 : 16,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: 1,
    letterSpacing: sp => sp ? '1.4px' : '1.6px',
    textAlign: 'center',
  },
  text: {
    fontSize: sp => sp ? 12 : 14,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: 1,
    letterSpacing: sp => sp ? '1.2px' : '1.4px',
    textAlign: 'center',
  },
  checkText: {
    fontSize: sp => sp ? 12 : 14,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: 1.3,
    letterSpacing: sp => sp ? '1.2px' : '1.4px',
    textAlign: 'left',
  },
  note: {
    maxWidth: 463,
    fontSize: 10,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: '16px',
    letterSpacing: '1.0px',
    margin: 'auto',
    marginBottom: sp => theme.spacing(sp ? 5.25 : 4.75),
  },
  consentContainer: {
    maxWidth: sp => sp ? 245 : 420,
    margin: 'auto',
    marginBottom: sp => theme.spacing(sp ? 5 : 4.5),
  },
  consentItem: {
    minHeight: 37,
  },
  checkbox: {
    color: '#423BC7',
    padding: 0,
    marginRight: theme.spacing(0.5),
  },
  selectbox: {
    fontSize: sp => sp ? 12 : 14,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: 1,
    letterSpacing: sp => sp ? '1.2px' : '1.4px',
    textAlign: 'center',
    margin: theme.spacing(0.25),
    '& .MuiOutlinedInput-input': {
      border: '2px solid #B0B4FC',
      borderRadius: 10,
      paddingTop: theme.spacing(0.75),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(0.75),
      paddingLeft: theme.spacing(0.75),
    },
    '& .MuiSelect-icon': {
      color: '#616AFA',
      top: 'calc(50% - 14px)',
    },
    '& fieldset': {
      display: 'none'
    },
  },
  selectItem: {
    minHeight: 32,
  },
  textField: {
    marginBottom: theme.spacing(1.25),
    '& .Mui-error': {
      '& input': {
        border: '2px solid red',
      }
    },
    '& .MuiOutlinedInput-root': {
      '& input': {
        width: sp => sp ? 297 : 485,
        height: sp => sp ? 38 : 41,
        color: '#433CC7',
        '&::placeholder': {
          color: '#DBDCFE'
        },
        fontSize: sp => sp ? 12 : 16,
        fontWeight: 600,
        lineHeight: 1,
        letterSpacing: sp => sp ? '1.2px' : '1.6px',
        boxSizing: 'border-box',
        border: '2px solid #423BC7',
        borderRadius: 27,
        paddingTop: 17,
        paddingRight: 19,
        paddingBottom: 17,
        paddingLeft: 19,
      },
      '& fieldset': {
        display: 'none'
      },
    },
  },
  sourceContainer: {
    textAlign: 'center',
    marginBottom: sp => theme.spacing(sp ? 3 : 7),
  },
  button: {
    color: '#ffffff',
    background: '#423BC7',
    width: spMatches => spMatches ? 243 : 392,
    height: spMatches => spMatches ? 49 : 79,
    fontSize: spMatches => spMatches ? 14 : 24,
    fontWeight: 600,
    letterSpacing: sp => sp ? '1.4px' : '2.4px',
    borderRadius: 50,
    display: 'block',
    margin: 'auto',
    marginBottom: theme.spacing(3.5),
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#423BC7',
    },
    '&.Mui-disabled': {
      color: '#ffffff',
      backgroundColor: '#E0E0E0'
    },
  },
}))

export default function SignUp (props) {
  const { company  } = props
  const spMatches = !useMediaQuery('(min-width:600px)')
  const classes = useStyles(spMatches)

  const [isError, setIsError] = useState(false)
  // メールアドレス
  const [mailAddress, setMailAddress] = useState('')
  const [mailAddressError, setMailAddressError] = useState(null)
  const [mailAddressConf, setMailAddressConf] = useState('')
  const [mailAddressConfError, setMailAddressConfError] = useState(null)
  // 所属企業
  const [checkedOrganization, setCheckedOrganization] = useState(false)
  const [organization, setOrganization] = useState(null)
  // 独身
  const [checkedUnmarried, setCheckedUnmarried] = useState(false)
  // 結婚意思
  const [checkedMarriageIntention, setCheckedMarriageIntention] = useState(false)
  const [selectedMarriageIntention, setSelectedMarriageIntention] = useState('yes')
  // 性別
  const [checkedSex, setCheckedSex] = useState(false)
  const [selectedSex, setSelectedSex] = useState('none')
  // 国内/海外
  const [checkedCountry, setCheckedCountry] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState('none')
  // 住所
  const [checkedPref, setCheckedPref] = useState(false)
  const [selectedPref, setSelectedPref] = useState('none')
  const [prefValues, setPrefValues] = useState(null)
  // 流入元
  const [selectedSource, setSelectedSource] = useState('none')
  const [freeTerm, setFreeTerm] = useState(null)
  const [campMaster, setCampMaster] = useState(null)
  const [openCarrierMailAddressAttentionDialog, setOpenCarrierMailAddressAttentionDialog] = useState(false)

  useLayoutEffect(() => {
    (async () => {
      if (isEmpty(company)) {
        props.setScreen('/')
        return
      }
      props.setLoading(true)
      try {
        const organization = await Api.getUserOrganization(company.organization_id)
        setOrganization(organization)
        const freeTerm = await Api.getFreeTerm(organization.id)
        setFreeTerm(freeTerm)
        const campMaster = await findCampaignMaster(organization)
        setCampMaster(campMaster)
        const prefs = await Api.getPrefs()
        setPrefValues(prefs)
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        props.setLoading(false)
      }
    })()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const findCampaignMaster = async (org) => {
    if (org) {
      const forOrg = await Api.getCampaignMaster({
        campaign_type: 'plan_ticket',
        organization_id: org.id,
      })
      if (forOrg) { return forOrg }
    }
    const forAllUsers = await Api.getCampaignMaster({
      campaign_type: 'plan_ticket',
    })
    return forAllUsers
  }

  const createSelectItem = (type) => {
    const map = {
      marriage_intention: { state: selectedMarriageIntention, values: marriageIntentionValues, onChange: (e) => setSelectedMarriageIntention(e.target.value) },
      sex: { state: selectedSex, values: sexValues, onChange: (e) => setSelectedSex(e.target.value) },
      country: { state: selectedCountry, values: countryValues, onChange: (e) => setSelectedCountry(e.target.value) },
      pref: { state: selectedPref, values: prefValues, onChange: (e) => setSelectedPref(e.target.value) },
      source: { state: selectedSource, values: inflowSourceValues, onChange: (e) => setSelectedSource(e.target.value) },
    }
    if (!map[type]) { return }
    const selected = map[type].state
    const onChange = map[type].onChange
    const values = map[type].values
    const defaultLabel = type === 'sex' ? '性別選択' : type === 'country' ? 'お住まい選択' : type === 'pref' ? '都道府県選択' : '選択'
    const label = (!selected || selected === 'none') ? defaultLabel : values.find(v => v.id === selected).display_name
    if (!values) { return }
    return (
      <Select
        className={classes.selectbox}
        value={selected}
        defaultValue="none"
        onChange={onChange}
        renderValue={() => label}
        input={<OutlinedInput />}
        style={{ minWidth: type === 'source' && 120 }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null
        }}
        data-testid={`selectbox-${type}`}
      >
        {values.map(value => (
          <MenuItem className={`${classes.text} ${classes.selectItem}`} key={value.id} value={value.id}>
            {value.display_name}
          </MenuItem>
        )
        )}
      </Select>
    )
  }

  const onChangeMailAddress = (e) => {
    const value = e.target.value
    setMailAddress(value)
    setMailAddressError(false)
  }

  const onChangeMailAddressConf = (e) => {
    const value = e.target.value
    setMailAddressConf(value)
    setMailAddressConfError(false)
  }

  const onBlurMailAddress = () => {
    if (!mailAddress || !mailAddressConf) { return }
    if (mailAddress !== mailAddressConf) {
      setMailAddressError('メールアドレスが一致しません。')
      setMailAddressConfError('メールアドレスが一致しません。')
      return
    }
    if (isCarrierAddress(mailAddress)) {
      setOpenCarrierMailAddressAttentionDialog(true)
      return
    }
    setMailAddressError(false)
    setMailAddressConfError(false)
  }

  const emailValidate = (value) => {
    if (isEmpty(value)) {
      return 'メールアドレスを入力してください。'
    } else if (!value.match(/^[a-zA-Z0-9_.+-]+[@][a-zA-Z0-9.-]+$/)) {
      return 'メールアドレスの形式が正しくありません。'
    } else if (value.length > 255) {
      return '最大文字数は255文字です。'
    } else {
      return true
    }
  }

  const isErrorText = (value) => {
    return typeof value === 'string'
  }

  const submitMailAddress = async () => {
    // 入力のバリデーション
    const mailResult = emailValidate(mailAddress)
    const mailConfResult = emailValidate(mailAddressConf)
    if (isErrorText(mailResult)) { setMailAddressError(mailResult) }
    if (isErrorText(mailConfResult)) { setMailAddressConfError(mailConfResult) }
    if (isErrorText(mailResult) || isErrorText(mailConfResult)) { return }
    const code = Storage.invitationCampaignCode.value || ''
    props.setLoading(true)
    try {
      Storage.authToken.clear()
      const params = {
        organization_id: organization ? organization.id : null,
        sex: selectedSex,
        pref_id: selectedPref,
        main_working_company_name: company.name,
        welfare_account_id: Storage.welfareAccountId.value,
        utm_source: Storage.utmPrams.value ? Storage.utmPrams.value.utm_source : null,
        utm_medium: Storage.utmPrams.value ? Storage.utmPrams.value.utm_medium : null,
        utm_campaign: Storage.utmPrams.value ? Storage.utmPrams.value.utm_campaign : null,
        utm_term: Storage.utmPrams.value ? Storage.utmPrams.value.utm_term : null,
        utm_content: Storage.utmPrams.value ? Storage.utmPrams.value.utm_content : null,
        inflow_source: selectedSource,
        marriage_intention: selectedMarriageIntention,
        invitation_campaign_code: decodeSanitaize(code),
      }
      const res = await Api.registerMailAddress(mailAddress, params)
      handleSuccess(res)
      Storage.invitationCampaignCode.clear()
    } catch (error) {
      handleError(error)
    } finally {
      props.setLoading(false)
    }
  }

  const handleSuccess = () => {
    Storage.welfareAccountId.clear()
    Storage.utmPrams.clear()
    props.setMailAddress(mailAddress)
    props.setScreen('SignupCheck')
  }

  const handleError = (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 403:
          setMailAddressError('このメールアドレスは使用できません。')
          break
        case 409:
          setMailAddressError('このメールアドレスは使用中です。')
          break
        default:
          BaseComponent.handleApiError(props, error)
      }
    } else {
      console.log(error)
    }
    setIsError(true)
  }

  const enabledSubmit = () => {
    return checkedOrganization
      && checkedUnmarried
      && checkedMarriageIntention
      && checkedSex
      && checkedCountry
      && checkedPref
      && organization
      && selectedSex !== 'none'
      && selectedCountry !== 'none'
      && selectedPref !== 'none'
      && selectedSource !== 'none'
      && mailAddress
      && mailAddressConf
      && mailAddress === mailAddressConf
  }

  if (!company) { return null }
  if (!organization) { return null }

  const status = company.name.endsWith('組合') ? '組合員'
    : (company.name.endsWith('連盟') || company.name.endsWith('会')) ? '会員'
      : '従業員'

  return (
    <>
      <EntryHeader />
      <div className={classes.body}>
        <Typography className={classes.title}>ENTRY</Typography>
        <div className={classes.titleBar} />
        <div className={classes.container}>
          <Typography className={classes.topText}>あなたの企業は利用対象企業です。</Typography>
          <Typography className={classes.text} style={{ marginBottom: spMatches ? 11 : 30 }}>仮登録へお進みください。</Typography>
          <EntryCampaignBox
            {...props}
            organization={organization}
            freeTerm={freeTerm}
            campMaster={campMaster}
          />
          <div style={{ paddingRight: 12, paddingLeft: 12, marginTop: spMatches ? 26 : 37.5 }}>
            <Typography className={classes.subtitle} style={{ marginBottom: spMatches ? 16 : 14 }}>同意確認（必須）</Typography>
            <Typography className={classes.text} style={{ marginBottom: spMatches ? 22 : 14 }}>同意項目にチェックをお願いします。</Typography>
            <div className={classes.consentContainer}>
              {/* 所属 */}
              <Grid className={classes.consentItem} container wrap="nowrap" direction="row" alignItems="center" justifyContent="flex-start">
                <Checkbox
                  className={classes.checkbox}
                  size={spMatches ? 'small' : 'medium'}
                  checked={checkedOrganization}
                  onChange={() => setCheckedOrganization(!checkedOrganization)}
                  value={checkedOrganization}
                  inputProps={{ 'data-testid': 'checkbox-organization' }}
                />
                <Typography className={classes.checkText} data-testid="label-organization">
                  私は{company.name}の{status}です
                </Typography>
              </Grid>
              {/* 独身 */}
              <Grid className={classes.consentItem} container wrap="nowrap" direction="row" alignItems="center" justifyContent="flex-start">
                <Checkbox
                  className={classes.checkbox}
                  size={spMatches ? 'small' : 'medium'}
                  checked={checkedUnmarried}
                  onChange={() => setCheckedUnmarried(!checkedUnmarried)}
                  value={checkedUnmarried}
                  inputProps={{ 'data-testid': 'checkbox-unmarried' }}
                />
                <Typography className={classes.checkText} data-testid="label-unmarried">
                  私は独身です
                </Typography>
              </Grid>
              {/* 結婚意思 */}
              <Grid className={classes.consentItem} container wrap="wrap" direction="row" alignItems="center" justifyContent="flex-start">
                <Checkbox
                  className={classes.checkbox}
                  size={spMatches ? 'small' : 'medium'}
                  checked={checkedMarriageIntention}
                  onChange={() => setCheckedMarriageIntention(!checkedMarriageIntention)}
                  value={checkedMarriageIntention}
                  inputProps={{ 'data-testid': 'checkbox-marriage-intention' }}
                />
                <Typography className={classes.checkText} data-testid="label-marriage-intention">
                  私は将来的に
                </Typography>
                {createSelectItem('marriage_intention')}
              </Grid>
              {/* 性別 */}
              <Grid className={classes.consentItem} container wrap="nowrap" direction="row" alignItems="center" justifyContent="flex-start">
                <Checkbox
                  className={classes.checkbox}
                  size={spMatches ? 'small' : 'medium'}
                  checked={checkedSex}
                  onChange={() => setCheckedSex(!checkedSex)}
                  value={checkedSex}
                  inputProps={{ 'data-testid': 'checkbox-sex' }}
                />
                <Typography className={classes.checkText} data-testid="label-sex">私は</Typography>
                {createSelectItem('sex')}
                <Typography className={classes.checkText}>です</Typography>
              </Grid>
              {/* 日本/海外  */}
              <Grid className={classes.consentItem} container wrap="nowrap" direction="row" alignItems="center" justifyContent="flex-start">
                <Checkbox
                  className={classes.checkbox}
                  size={spMatches ? 'small' : 'medium'}
                  checked={checkedCountry}
                  onChange={() => setCheckedCountry(!checkedCountry)}
                  value={checkedCountry}
                  inputProps={{ 'data-testid': 'checkbox-country' }}
                />
                <Typography className={classes.checkText} data-testid="label-country">私は</Typography>
                {createSelectItem('country')}
                <Typography className={classes.checkText}>在住です</Typography>
              </Grid>
              {/* 住まい */}
              <Grid className={classes.consentItem} container wrap="nowrap" direction="row" alignItems="center" justifyContent="flex-start">
                <Checkbox
                  className={classes.checkbox}
                  size={spMatches ? 'small' : 'medium'}
                  checked={checkedPref}
                  onChange={() => setCheckedPref(!checkedPref)}
                  value={checkedPref}
                  inputProps={{ 'data-testid': 'checkbox-pref' }}
                />
                <Typography className={classes.checkText} style={{ whiteSpace: 'nowrap' }} data-testid="label-pref">私は</Typography>
                {createSelectItem('pref')}
                <Typography className={classes.checkText} style={{ whiteSpace: 'nowrap' }}>
                  {selectedCountry === 'others' ? <>居住予定</> : <>在住</>}です
                </Typography>
              </Grid>
            </div>
            {selectedCountry === 'others' && (
              <Typography className={classes.note}>
                ＊日本国内の居住地が未定の場合は、出会いを希望する都道府県をご選択ください。
              </Typography>
            )}
            <Grid container direction="column" alignItems="center">
              <Typography className={classes.subtitle} style={{ marginBottom: spMatches ? 19 : 15 }}>メールアドレス登録</Typography>
              <TextField
                className={classes.textField}
                id="mailAddress"
                type="email"
                variant="outlined"
                error={Boolean(mailAddressError)}
                helperText={mailAddressError}
                placeholder="メールアドレス"
                color="secondary"
                onChange={onChangeMailAddress}
                onBlur={onBlurMailAddress}
              />
              <TextField
                className={classes.textField}
                id="mailAddress"
                type="email"
                variant="outlined"
                error={Boolean(mailAddressConfError)}
                helperText={mailAddressConfError}
                placeholder="メールアドレス（確認）"
                color="secondary"
                onChange={onChangeMailAddressConf}
                onBlur={onBlurMailAddress}
              />
            </Grid>
            <Typography className={classes.note}>
              メールアドレスにお間違えがないか再度ご確認ください。<br />
              特にドコモメールアドレスで、本登録メールが届かないケースが多く発生しています。<br />
              受信設定の見直し、またはドコモ以外のメールアドレスでご登録ください。
            </Typography>
            <Typography className={classes.subtitle} style={{ marginBottom: 14 }}>アンケート（必須）</Typography>
            <Typography className={classes.text} style={{ marginBottom: spMatches ? 17 : 15 }}>Aill goenをどのように知りましたか？</Typography>
            <div className={classes.sourceContainer}>
              {createSelectItem('source')}
            </div>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              disabled={!enabledSubmit()}
              onClick={submitMailAddress}
            >
              仮登録を行う
            </Button>
            <Typography className={classes.note}>
              5分以内に本登録メールが届かない場合、次の可能性がございますのでご確認ください。<br />
              「迷惑メール」フォルダなど、異なるボックスで受信している PCメールアドレスの受信拒否や高いセキュリティ設定がされている<br />
              設定を変更されましたら再度メールアドレスの登録をお願いします。<br />
              以上を確認しても解決しない場合は「support@aill.ai」までご連絡ください。
            </Typography>
          </div>
        </div>
      </div>
      <EntryFooter />
      <CarrierMailAddressAttentionDialog
        open={openCarrierMailAddressAttentionDialog}
        onClose={() => setOpenCarrierMailAddressAttentionDialog(false)}
      />
      <ConfirmDialog
        open={isError}
        onClose={() => setIsError(false)}
        confirm="yes"
        title="サインアップ"
        message="サインアップできませんでした。"
      />
    </>
  )
}

SignUp.propTypes = {
  company: PropTypes.object,
  setScreen: PropTypes.func,
  setLoading: PropTypes.func,
  setMailAddress: PropTypes.func,
  setPreRegistered: PropTypes.func,
}
