import { connect } from 'react-redux'
import { push, replace } from 'react-router-redux'
import AppActions from 'actions/app'
import { setFriend } from 'commons/storage'
import App from 'components/app'

// stateから必要なプロパティ変換？
function mapStateToProps (state) {
  return state
}

/*
* dispatch関数(ユーザーアクション)を受けてプロパティ変換
* 要するにthis.props.handleClickでアクセス可能になる
* actionファイルに書かれた処理をまとめてbindActionCreators()で変換すると楽できる
*/
function mapDispatchToProps (dispatch) {
  return {
    setScreen: (screen) => {
      dispatch(AppActions.screen(screen))
      switch(screen) {
        case '/':
        case 'Landing':
        case 'Home':
        case 'FriendRequest':
        case 'Friends':
        case 'Campaigns':
          dispatch(replace(screen))
          break
        default:
          dispatch(push(screen))
          break
      }
    },
    setAuthId: (authId) => { dispatch(AppActions.authId(authId)) },
    setMailAddress: (mailAddress) => { dispatch(AppActions.mailAddress(mailAddress)) },
    setPreRegistered: (preRegistered) => { dispatch(AppActions.preRegistered(preRegistered)) },
    setPassword: (password) => { dispatch(AppActions.password(password)) },
    setIsLogin: (isLogin) => { dispatch(AppActions.isLogin(isLogin)) },
    setUserId: (userId) => { dispatch(AppActions.userId(userId)) },
    setMaster: (master) => { dispatch(AppActions.master(master)) },
    setUser: (user) => { dispatch(AppActions.user(user)) },
    setUserImage: (userImage) => { dispatch(AppActions.userImage(userImage)) },
    setIdentifyImage: (identifyImage) => { dispatch(AppActions.identifyImage(identifyImage)) },
    setAffiliationImage: (affiliationImage) => { dispatch(AppActions.affiliationImage(affiliationImage)) },
    setFederationToken: (federationToken) => { dispatch(AppActions.federationToken(federationToken)) },
    setFriend: (friend) => {
      dispatch(AppActions.friend(friend))
      setFriend(friend)
    },
    setFriends: (friends) => { dispatch(AppActions.friends(friends)) },
    setEntries: (entries) => { dispatch(AppActions.entries(entries)) },
    setAppLoading: (appLoading) => { dispatch(AppActions.appLoading(appLoading)) },
    setLoading: (loading) => { dispatch(AppActions.loading(loading)) },
    setOrganizationId: (organizationId) => { dispatch(AppActions.organizationId(organizationId)) },
    setMyOrganization: (myOrganization) => { dispatch(AppActions.myOrganization(myOrganization)) },
    setErrorMessage: (errorMessage, onCloseErrorMessage = null) => {
      dispatch(AppActions.onCloseErrorMessage(onCloseErrorMessage))
      dispatch(AppActions.errorMessage(errorMessage))
    },
    setImportantErrorMessage: (importantErrorMessage, onCloseImportantErrorMessage = null) => {
      dispatch(AppActions.onCloseImportantErrorMessage(onCloseImportantErrorMessage))
      dispatch(AppActions.importantErrorMessage(importantErrorMessage))
    },
    setRequestSuccessMessage: (requestSuccessMessage, onCloseRequestSuccessMessage = null, firstNaviFlag = false) => {
      dispatch(AppActions.onCloseRequestSuccessMessage(onCloseRequestSuccessMessage))
      dispatch(AppActions.requestSuccessMessage(requestSuccessMessage))
      dispatch(AppActions.firstNaviFlag(firstNaviFlag))
    },
    setHideAppNavigation: (hideAppNavigation) => { dispatch(AppActions.hideAppNavigation(hideAppNavigation)) },
    setCompany: (company) => { dispatch(AppActions.company(company)) },
    setNewMessage: (newMessage) => { dispatch(AppActions.newMessage(newMessage)) },
    setShopSearch: (shopSearch) => { dispatch(AppActions.shopSearch(shopSearch)) },
    setShopDetail: (shopDetail) => { dispatch(AppActions.shopDetail(shopDetail)) },
    setMatchings: (matchings) => { dispatch(AppActions.matchings(matchings)) },
    setNotifications: (notifications) => { dispatch(AppActions.notifications(notifications)) },
    setChatRooms: (chatrooms) => { dispatch(AppActions.chatrooms(chatrooms)) },
    setAdmin: (admin) => { dispatch(AppActions.admin(admin)) },
    setIdText: (idText) => { dispatch(AppActions.idText(idText)) },
    setAffiliationText: (affiliationText) => { dispatch(AppActions.affiliationText(affiliationText)) },
    setMatchingState: (matchingState) => { dispatch(AppActions.matchingState(matchingState)) },
    setSubscription: (subscription) => { dispatch(AppActions.subscription(subscription)) },
    setTutorialReadStates: (tutorialReadStates) => { dispatch(AppActions.tutorialReadStates(tutorialReadStates)) },
    setTutorialView: (tutorialView) => { dispatch(AppActions.tutorialView(tutorialView)) },
    setSpecialOffers: (specialOffers) => { dispatch(AppActions.specialOffers(specialOffers)) },
    setCampaign: (campaign) => { dispatch(AppActions.campaign(campaign)) },
    setPhoneNumber: (phoneNumber, isPhoneVerified) => {
      dispatch(AppActions.phoneNumber(phoneNumber))
      dispatch(AppActions.isPhoneVerified(isPhoneVerified))
    },
    setDisableSmsVerification: (disableSmsVerification) => {
      dispatch(AppActions.disableSmsVerification(disableSmsVerification))
    },
  }
}

//connectでReduxとReactのコンポーネントを繋ぐ
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App)
